import React, { useState } from "react";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import "./EventsTable.css"; // Make sure this CSS file is created and imported

const EventTable = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [events, setEvents] = useState([
    {
      date: "2024-07-15",
      name: "Rent Deadline",
      description: "Pay your rent in full by 15th",
      emoji: "💶",
    },
    {
      date: "2024-07-25",
      name: "Electricity Deadline",
      description: "Pay KPLC by 25th to avoid disconnection!",
      emoji: "💡",
    },
  ]);
  const [newEvent, setNewEvent] = useState({
    date: "",
    name: "",
    description: "",
    emoji: "",
  });

  const Snackbar = ({ message, onClose, duration }) => {
    useEffect(() => {
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    }, [onClose, duration]);

    return (
      <Alert variant="success" onClose={onClose} dismissible>
        {message}
      </Alert>
    );
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleNotifyAll = () => {
    setSnackbarMessage("All members notified.");
    setShowSnackbar(true);
  };

  const handleAddEvent = () => {
    const { date, name, description, emoji } = newEvent;
    if (!date || !name || !description || !emoji) {
      alert("Please fill out all fields before adding an event.");
      return;
    }
    setEvents([...events, newEvent]);
    setNewEvent({ date: "", name: "", description: "", emoji: "" });
  };

  const handleDeleteEvent = (index) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      setEvents(events.filter((_, i) => i !== index));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  return (
    <div className="container mt-5">
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          onClose={closeSnackbar}
          duration={5000}
        />
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Events</h3>
      </div>
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Event Name</th>
              <th>Event Description</th>
              <th>Emoji</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>{event.date}</td>
                <td>{event.name}</td>
                <td>{event.description}</td>
                <td>{event.emoji}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteEvent(index)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-success btn-md mx-2"
                    onClick={handleNotifyAll}
                  >
                    Notify
                  </button>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <input
                  type="date"
                  name="date"
                  value={newEvent.date}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  name="name"
                  value={newEvent.name}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                />
              </td>
              <td>
                <textarea
                  name="description"
                  value={newEvent.description}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                ></textarea>
              </td>
              <td>
                <select
                  name="emoji"
                  value={newEvent.emoji}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                >
                  <option value="">Select Emoji</option>
                  <option value="😀">😀</option>
                  <option value="🎉">🎉</option>
                  <option value="🎂">🎂</option>
                  <option value="🎁">🎁</option>
                </select>
              </td>
              <td>
                <button
                  className="btn btn-success btn-md"
                  onClick={handleAddEvent}
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventTable;
