import React from "react";
import "./NotificationsInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NotificationsComponent from "../NotificationsComponent/NotificationsComponent";

const PaymentsInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <NotificationsComponent />
    </Box>
  );
};

export default PaymentsInitial;
