import React from "react";
import SideBarDashboard from "../../Components/SideBarDashboard/SideBarDashboard";
import "./Dashboard.css";

const Dashboard = () => {
  return (
    <div>
      <SideBarDashboard />
    </div>
  );
};

export default Dashboard;
