import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./TenantsInitial.css";
import TenantsComponent from "../TenantsComponent/TenantsComponent";

const TenantsInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <TenantsComponent />
    </Box>
  );
};

export default TenantsInitial;
