import React, { useState } from "react";
import "./ProfileInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Dp from "../../Assets/Images/person.png";
import { Link } from "react-router-dom";

const PaymentsInitial = () => {
  const [profileImage, setProfileImage] = useState(Dp);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainProfileDiv">
        <div className="firstDiv">
          <div className="dp">
            <img
              src={profileImage}
              alt="profile"
              width="80px"
              height="80px"
              style={{ borderRadius: "40px" }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="upload-profile-image"
            />
            <label htmlFor="upload-profile-image" className="upload-icon">
              &#x1F4C2; {/* This is a folder icon for upload */}
            </label>
            <label>Nyagah Mbugua</label>
          </div>
          <div className="menus">
            <button className="navBtns">Personal Details</button>

            <Link className="linkNoDecoration navBtns" to="/Repository">
              <button className="navBtns">Repository</button>
            </Link>

            <button className="navBtns">Change Password</button>
          </div>
        </div>
        <div className="verticalLine"></div>
        <div className="secondDiv">
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Id Number</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                value="3465653622"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="Nyagah Mbugua"
                readOnly
              />
            </div>
          </form>
        </div>

        <div className="thirdDiv">
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                value="077777777"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Religion</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="Christianity"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Gender</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                value="Male"
                readOnly
              />
            </div>
          </form>
        </div>
      </div>
      <div className="lowerUpdateDiv">
        <button className="btn btn-md btn-success">Update</button>
      </div>
    </Box>
  );
};

export default PaymentsInitial;
