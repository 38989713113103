import React from "react";
import "./PaymentsTable.css";

const PaymentsTable = () => {
  const handlePrint = () => {
    window.print();
  };
  // Dummy data
  const data = [
    { date: "2024-06-01", member: "John Doe", payment: "Loan", amount: 1500 },
    {
      date: "2024-06-01",
      member: "Jane Smith",
      payment: "AGM contribution",
      amount: 200,
    },
    {
      date: "2024-06-01",
      member: "Peter Parker",
      payment: "Fine",
      amount: 300,
    },
    {
      date: "2024-06-01",
      member: "Bruce Wayne",
      payment: "Shares",
      amount: 1000,
    },
    { date: "2024-06-02", member: "Clark Kent", payment: "Loan", amount: 2500 },
    {
      date: "2024-06-02",
      member: "Diana Prince",
      payment: "AGM contribution",
      amount: 150,
    },
    { date: "2024-06-02", member: "Tony Stark", payment: "Fine", amount: 400 },
    {
      date: "2024-06-02",
      member: "Steve Rogers",
      payment: "Shares",
      amount: 1200,
    },
    {
      date: "2024-06-03",
      member: "Natasha Romanoff",
      payment: "Loan",
      amount: 3000,
    },
    {
      date: "2024-06-03",
      member: "Bruce Banner",
      payment: "AGM contribution",
      amount: 250,
    },
    { date: "2024-06-03", member: "Barry Allen", payment: "Fine", amount: 500 },
    {
      date: "2024-06-03",
      member: "Hal Jordan",
      payment: "Shares",
      amount: 1500,
    },
    {
      date: "2024-06-04",
      member: "Arthur Curry",
      payment: "Loan",
      amount: 3500,
    },
    {
      date: "2024-06-04",
      member: "Victor Stone",
      payment: "AGM contribution",
      amount: 300,
    },
    {
      date: "2024-06-04",
      member: "Oliver Queen",
      payment: "Fine",
      amount: 600,
    },
    {
      date: "2024-06-04",
      member: "Ray Palmer",
      payment: "Shares",
      amount: 1800,
    },
    {
      date: "2024-06-04",
      member: "Kara Danvers",
      payment: "Loan",
      amount: 4000,
    },
    {
      date: "2024-06-04",
      member: "John Constantine",
      payment: "AGM contribution",
      amount: 350,
    },
    {
      date: "2024-06-04",
      member: "Zatanna Zatara",
      payment: "Fine",
      amount: 700,
    },
    { date: "2024-06-04", member: "Shazam", payment: "Shares", amount: 2000 },
  ];

  const groupedData = data.reduce((acc, entry) => {
    const date = entry.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(entry);
    return acc;
  }, {});

  return (
    <div className="payments-container">
      <div className="printPayments w-100 text-end p-4">
        <button className="btn btn-md btn-success" onClick={handlePrint}>
          print payments
        </button>
      </div>
      <h2 className="payments-title">Payments</h2>
      <div className="payments-content">
        {Object.entries(groupedData).map(([date, entries]) => (
          <div key={date} className="payment-group">
            <h3>{date}</h3>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Member (Payee)</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Amount (Ksh)</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.member}</td>
                    <td>{entry.payment}</td>
                    <td>Ksh {entry.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentsTable;
