import React, { useState } from "react";
import { Container, Button, Form, Table, Alert } from "react-bootstrap";
import "./NotifyComponent.css"; // Custom CSS file for additional styling

const NotifyPage = () => {
  // State variables
  const [type, setType] = useState("tenants"); // 'complaint' or 'compliment'
  const [message, setMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [formValid, setFormValid] = useState(false); // State to track form validity

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() !== "") {
      setShowConfirmation(true);
      setFormValid(true); // Mark the form as valid
    } else {
      setFormValid(false); // Mark the form as invalid
    }
  };

  // Handle confirmation (send message)
  const handleConfirm = () => {
    // Add new notification to the list
    const newNotification = {
      id: notifications.length + 1,
      type: type,
      message: message,
    };
    setNotifications([...notifications, newNotification]);

    // Reset form fields
    setMessage("");
    setShowConfirmation(false);

    // Show snackbar for 3 seconds (optional)
    setTimeout(() => {
      // Do something after 3 seconds if needed
    }, 3000);
  };

  // Handle cancel (clear message)
  const handleCancel = () => {
    setMessage("");
    setShowConfirmation(false);
    setFormValid(false); // Clear form validity state on cancel
  };

  return (
    <Container className="notify-page mt-4" style={{ maxWidth: "95%" }}>
      <h2 className="text-start mb-4">make Notifications</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>For Who:</Form.Label>
          <Form.Control
            as="select"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="caretaker">Caretaker</option>
            <option value="tenants">Tenants</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Message:</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            isInvalid={!formValid && message.trim() === ""} // Show validation state
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter a message.
          </Form.Control.Feedback>
        </Form.Group>
        <div className="text-start my-2">
          <Button variant="primary" type="submit">
            Send
          </Button>
        </div>
      </Form>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="confirmation-modal mt-4">
          <Alert variant="info">
            <p className="text-center">
              Are you sure you want to send this message to {type}?
            </p>
            <div className="text-center">
              <Button variant="success" onClick={handleConfirm}>
                Confirm
              </Button>{" "}
              <Button variant="danger" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Alert>
        </div>
      )}

      {/* Notification Table */}
      {notifications.length > 0 && (
        <div className="notifications mt-5">
          <h3 className="text-start mb-3">Notifications Sent:</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial</th>
                <th>For</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr key={notification.id}>
                  <td>{notification.id}</td>
                  <td>{notification.type}</td>
                  <td>{notification.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default NotifyPage;
