import React from "react";
import { Table, Container } from "react-bootstrap";

const MovingOutTable = () => {
  // Sample data
  const data = [
    {
      serialNumber: 1,
      name: "John Doe",
      unit: 2,
      room: "2D",
      month: "January",
    },
    {
      serialNumber: 2,
      name: "Jane Smith",
      unit: 6,
      room: "6C",
      month: "February",
    },
    {
      serialNumber: 3,
      name: "Michael Johnson",
      unit: 7,
      room: "7A",
      month: "March",
    },
    {
      serialNumber: 4,
      name: "Emily Davis",
      unit: 5,
      room: "5A",
      month: "April",
    },
    {
      serialNumber: 5,
      name: "David Wilson",
      unit: 3,
      room: "3B",
      month: "May",
    },
  ];

  return (
    <Container className="mt-5">
      <h2 className="text-start mb-4">Moving out Applications</h2>
      <Table striped hover>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Room</th>
            <th>Month</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.serialNumber}</td>
              <td>{item.name}</td>
              <td>{item.unit}</td>
              <td>{item.room}</td>
              <td>{item.month}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MovingOutTable;
