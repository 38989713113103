import React from "react";
import Table from "react-bootstrap/Table";
import "./TenantsComponent.css";

// Dummy data for tenants
const tenantsData = [
  { id: 1, name: "John Doe", room: "1A", phone: "12345" },
  { id: 2, name: "Jane Smith", room: "1B", phone: "23456" },
  { id: 3, name: "Michael Johnson", room: "1C", phone: "34567" },
  { id: 4, name: "Emily Brown", room: "1D", phone: "45678" },
  { id: 5, name: "James Wilson", room: "2A", phone: "56789" },
  { id: 6, name: "Sarah Taylor", room: "2B", phone: "67890" },
  { id: 7, name: "David Clark", room: "2C", phone: "78901" },
  { id: 8, name: "Emma Martinez", room: "2D", phone: "89012" },
  { id: 9, name: "Daniel Lee", room: "3A", phone: "90123" },
  { id: 10, name: "Olivia Garcia", room: "3B", phone: "01234" },
  { id: 11, name: "Liam Rodriguez", room: "3C", phone: "12345" },
  { id: 12, name: "Ava Hernandez", room: "3D", phone: "23451" },
  { id: 13, name: "Noah Lopez", room: "4A", phone: "34567" },
  { id: 14, name: "Sophia Gonzalez", room: "4B", phone: "45678" },
  { id: 15, name: "Elijah Perez", room: "4C", phone: "56789" },
  { id: 16, name: "Isabella Sanchez", room: "4D", phone: "67890" },
  { id: 17, name: "William Torres", room: "5A", phone: "78901" },
  { id: 18, name: "Amelia Ramirez", room: "5B", phone: "89012" },
  { id: 19, name: "Benjamin Flores", room: "5C", phone: "90123" },
  { id: 20, name: "Mia Cruz", room: "5D", phone: "01234" },
  { id: 21, name: "Henry Gomez", room: "6A", phone: "12345" },
  { id: 22, name: "Charlotte Reyes", room: "6B", phone: "23456" },
  { id: 23, name: "Alexander Rivera", room: "6C", phone: "34567" },
  { id: 24, name: "Ella Torres", room: "6D", phone: "45678" },
  { id: 25, name: "Jacob Mendez", room: "7A", phone: "56789" },
  { id: 26, name: "Victoria Morales", room: "7B", phone: "67890" },
  { id: 27, name: "Michael Sullivan", room: "7C", phone: "78901" },
  { id: 28, name: "Grace Nguyen", room: "7D", phone: "89012" },
  { id: 29, name: "Lucas Kim", room: "8A", phone: "90123" },
  { id: 30, name: "Sophie Patel", room: "8B", phone: "01234" },
  { id: 31, name: "Daniel Roberts", room: "8C", phone: "12345" },
  { id: 32, name: "Madison Khan", room: "8D", phone: "23456" },
  { id: 33, name: "Logan Tran", room: "9A", phone: "34567" },
  { id: 34, name: "Chloe Walker", room: "9B", phone: "45678" },
  { id: 35, name: "Gabriel Li", room: "9C", phone: "56789" },
  { id: 36, name: "Avery King", room: "9D", phone: "67890" },
  { id: 37, name: "Jackson Cooper", room: "10A", phone: "78901" },
  { id: 38, name: "Sofia Young", room: "10B", phone: "89012" },
  { id: 39, name: "Joseph Hernandez", room: "10C", phone: "90123" },
  { id: 40, name: "Lily Hill", room: "10D", phone: "01234" },
  { id: 41, name: "Jack Baker", room: "11A", phone: "12345" },
  { id: 42, name: "Hannah Nguyen", room: "11B", phone: "23456" },
  { id: 43, name: "Samuel Thomas", room: "11C", phone: "34567" },
  { id: 44, name: "Aria Walker", room: "11D", phone: "45678" },
  { id: 45, name: "Sebastian Martinez", room: "12A", phone: "56789" },
  { id: 46, name: "Scarlett Allen", room: "12B", phone: "67890" },
  { id: 47, name: "Wyatt Young", room: "12C", phone: "78901" },
  { id: 48, name: "Zoe Scott", room: "12D", phone: "89012" },
  { id: 49, name: "Jayden Green", room: "13A", phone: "90123" },
  { id: 50, name: "Natalie Perez", room: "13B", phone: "01234" },
  { id: 51, name: "Julian Baker", room: "13C", phone: "12345" },
  { id: 52, name: "Leah Reed", room: "13D", phone: "23456" },
  { id: 53, name: "Carter Mitchell", room: "14A", phone: "34567" },
  { id: 54, name: "Mila Wright", room: "14B", phone: "45678" },
];

const TenantsTable = () => {
  // Function to group tenants by floor and unit
  const groupTenantsByFloorUnit = () => {
    const grouped = {};

    tenantsData.forEach((tenant, index) => {
      const { name, room, phone } = tenant;
      const id = `365${(index + 1).toString().padStart(5, "0")}`; // Ensure id starts with 365 and is 8 digits long
      const floor = Math.ceil((index + 1) / 20); // Assuming 20 tenants per floor (5 units * 4 rooms)
      const unit = Math.ceil(((index + 1) % 20) / 4); // Assuming 4 rooms per unit

      // Correct calculation for units and floors based on the new information
      const correctedFloor = Math.ceil((index + 1) / 20); // Correcting for floors
      const correctedUnit =
        (index + 1) % 20 === 0 ? 5 : Math.ceil(((index + 1) % 20) / 4); // Correcting for units

      // Create floor level if not exists
      if (!grouped[correctedFloor]) {
        grouped[correctedFloor] = {};
      }

      // Create unit level if not exists
      if (!grouped[correctedFloor][correctedUnit]) {
        grouped[correctedFloor][correctedUnit] = [];
      }

      // Format phone number as per requirement
      const formattedPhone = `07456${phone}`;

      // Add tenant to the unit
      grouped[correctedFloor][correctedUnit].push({
        id,
        name,
        room,
        phone: formattedPhone,
      });
    });

    return grouped;
  };

  // Get grouped tenants
  const groupedTenants = groupTenantsByFloorUnit();

  return (
    <div className="mt-4">
      <div className="printerr text-end">
        <button
          className="btn btn-md btn-success"
          onClick={() => window.print()}
        >
          print tenants
        </button>
      </div>

      <h2>Tenants Table</h2>
      {Object.keys(groupedTenants).map((floor) => (
        <div key={floor}>
          <h3>Floor {floor}</h3>
          {Object.keys(groupedTenants[floor]).map((unit) => (
            <div key={unit}>
              <h4>Unit {unit}</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Room</th>
                    <th>Name</th>
                    <th>ID</th>
                    <th>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedTenants[floor][unit].map((tenant) => (
                    <tr key={tenant.id}>
                      <td>{tenant.room}</td>
                      <td>{tenant.name}</td>
                      <td>{tenant.id}</td>
                      <td>
                        <a
                          className="phoneNoDecoration"
                          href={`tel:${tenant.phone}`}
                        >
                          {tenant.phone}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TenantsTable;
