import React from "react";
import SideBarNotify from "../../Components/SideBarNotify/SideBarNotify";
import "./Notify.css";
const Notify = () => {
  return (
    <div>
      <SideBarNotify />
    </div>
  );
};

export default Notify;
