import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const ArrearsTable = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAllAlert, setShowAllAlert] = useState(false);

  const arrearsData = [
    {
      serial: 1,
      name: "John Doe",
      unit: 1,
      room: "1A",
      for: "Rent",
      amount: 20000,
    },
    {
      serial: 2,
      name: "Jane Smith",
      unit: 2,
      room: "2D",
      for: "Deposit",
      amount: 1000,
    },
    {
      serial: 3,
      name: "Michael Brown",
      unit: 7,
      room: "7C",
      for: "Rent",
      amount: 30000,
    },
    {
      serial: 4,
      name: "Emily Davis",
      unit: 8,
      room: "8B",
      for: "Rent",
      amount: 45000,
    },
    {
      serial: 5,
      name: "David Wilson",
      unit: 9,
      room: "9C",
      for: "Deposit",
      amount: 5000,
    },
    {
      serial: 6,
      name: "Sarah Johnson",
      unit: 1,
      room: "1C",
      for: "Rent",
      amount: 15000,
    },
  ];

  const handleNotify = (name) => {
    setAlertMessage(`${name} has been notified.`);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 5000); // Close alert after 5 seconds
  };

  const handleNotifyAll = () => {
    setShowAllAlert(true);
    setTimeout(() => setShowAllAlert(false), 5000); // Close alert after 5 seconds
  };

  return (
    <div>
      <h2 className="text-center mt-4 mb-4">ARREARS</h2>
      {/* Alert for individual notification */}
      <Alert
        show={showAlert}
        variant="success"
        onClose={() => setShowAlert(false)}
        dismissible
      >
        {alertMessage}
      </Alert>
      {/* Alert for Notify All */}
      <Alert
        show={showAllAlert}
        variant="success"
        onClose={() => setShowAllAlert(false)}
        dismissible
      >
        All members have been notified.
      </Alert>
      <Table striped hover>
        <thead>
          <tr>
            <th>Serial</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Room</th>
            <th>For</th>
            <th>Amount</th>
            <th>Notify</th>
          </tr>
        </thead>
        <tbody>
          {arrearsData.map((row, index) => (
            <tr key={index}>
              <td>{row.serial}</td>
              <td>{row.name}</td>
              <td>{row.unit}</td>
              <td>{row.room}</td>
              <td>{row.for}</td>
              <td>{row.amount} KES</td>
              <td>
                <Button variant="info" onClick={() => handleNotify(row.name)}>
                  Notify
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <Button variant="success" onClick={handleNotifyAll}>
          Notify All
        </Button>
      </div>
    </div>
  );
};

export default ArrearsTable;
