import React, { useState } from "react";
import { Table } from "react-bootstrap";

const Notifications = () => {
  // Dummy data for notifications
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: "tenant",
      message: "Complaint on leakages",
      date: new Date("2024-06-20"),
    },
    {
      id: 2,
      type: "tenant",
      message: "Complaint on damages",
      date: new Date("2024-06-21"),
    },
    {
      id: 3,
      type: "tenant",
      message: "Complaint on water outages",
      date: new Date("2024-06-22"),
    },
    {
      id: 4,
      type: "tenant",
      message: "Complaint on leakages",
      date: new Date("2024-06-20"),
    },
    {
      id: 5,
      type: "tenant",
      message: "Complaint on damages",
      date: new Date("2024-06-21"),
    },
    {
      id: 6,
      type: "caretaker",
      message: "KPLC cut power to the pump",
      date: new Date("2024-06-22"),
    },
  ]);

  // Function to group notifications by date
  const groupNotificationsByDate = () => {
    const groupedNotifications = notifications.reduce((acc, notification) => {
      const dateKey = notification.date.toISOString().split("T")[0]; // Group by date without time
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(notification);
      return acc;
    }, {});

    return groupedNotifications;
  };

  const groupedNotifications = groupNotificationsByDate();

  return (
    <div style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "bold", marginBottom: "15px" }}>
        Notifications
      </h2>
      {Object.keys(groupedNotifications).map((dateKey) => (
        <div key={dateKey} style={{ marginBottom: "20px" }}>
          <h4 style={{ fontWeight: "400", marginBottom: "10px" }}>
            {new Date(dateKey).toDateString()}
          </h4>
          <Table striped hover>
            <thead style={{ fontWeight: "bold" }}>
              <tr>
                <th>Serial</th>
                <th>Name</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {groupedNotifications[dateKey].map((notification, index) => (
                <tr key={notification.id}>
                  <td>{index + 1}</td>
                  <td>
                    {notification.type === "tenant" ? "Tenant" : "Caretaker"}
                  </td>
                  <td>{notification.message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default Notifications;
