import React from "react";
import Login from "./Pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import Notifications from "./Pages/Notifications/Notifications";
import Repository from "./Pages/Repository/Repository";
import VacantRooms from "./Pages/VacantRooms/VacantRooms";
import Notify from "./Pages/Notify/Notify";
import Tenants from "./Pages/Tenants/Tenants";
import Arrears from "./Pages/Arrears/Arrears";
import Events from "./Pages/Events/Events";
import Payments from "./Pages/Payments/Payments";
import Caretaker from "./Pages/Caretaker/Caretaker";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Notifications" element={<Notifications />} />
        <Route path="/Repository" element={<Repository />} />
        <Route path="/VacantRooms" element={<VacantRooms />} />
        <Route path="/Notify" element={<Notify />} />
        <Route path="/Tenants" element={<Tenants />} />
        <Route path="/Arrears" element={<Arrears />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/Payments" element={<Payments />} />
        <Route path="/Caretaker" element={<Caretaker />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
