import React from "react";
import SideBarCaretaker from "../../Components/SideBarCaretaker/SideBarCaretaker";

const Caretaker = () => {
  return (
    <div>
      <SideBarCaretaker />
    </div>
  );
};

export default Caretaker;
