import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./CaretakerInitial.css";

const CaretakerInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />

      <div className="mainProfileDiv caretaker">
        <div className="verticalLine"></div>
        <div className="secondDiv">
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Id Number</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                value="3465653622"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="Nyagah Mbugua"
                readOnly
              />
            </div>
          </form>
        </div>

        <div className="thirdDiv">
          <form>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                value="077777777"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Religion</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="Christianity"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">Gender</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                value="Male"
                readOnly
              />
            </div>
          </form>
        </div>
      </div>
      <div className="lowerUpdateDiv">
        <button className="btn btn-md btn-success">Update</button>
      </div>
    </Box>
  );
};

export default CaretakerInitial;
