import React from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { FaBell, FaDoorOpen, FaSignOutAlt } from "react-icons/fa";
import ReactSpeedometer from "react-d3-speedometer";
import "./DashboardComponent.css";
import { Link } from "react-router-dom";

const CaretakerDashboard = () => {
  // Example data (can be fetched from API or stored locally)
  const username = "Nyagah";
  const notificationsCount = 5;
  const vacantRoomsCount = 3;
  const tenantsMovingOutCount = 5;
  const totalReceived = 300000; // Total received this month
  const totalPending = 76087; // Total pending amount
  const totalAmount = totalReceived + totalPending; // Total amount
  const percentage = Math.round((totalReceived / totalAmount) * 100); // Ratio of received to total

  return (
    <Container className="mt-4">
      <Card className="mb-4 card-shadow bg-primary-gradient text-white">
        <Card.Body>
          <Card.Title>Welcome back, {username}!</Card.Title>
          <Card.Text className="text-light">
            A simple yet elegant digital solution!
          </Card.Text>
        </Card.Body>
      </Card>

      <Row className="mt-4">
        <Col md={4}>
          <Link to="/Notifications">
            <Card className="mb-4 card-shadow bg-info-gradient text-white hover-focus">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title>
                    <FaBell className="me-2" /> Notifications
                  </Card.Title>
                  <Card.Text>Check your latest notifications</Card.Text>
                </div>
                <Badge bg="light" text="dark" pill>
                  {notificationsCount}
                </Badge>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/VacantRooms">
            <Card className="mb-4 card-shadow bg-success-gradient text-white hover-focus">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title>
                    <FaDoorOpen className="me-2" /> Vacant Rooms
                  </Card.Title>
                  <Card.Text>View all vacant rooms</Card.Text>
                </div>
                <Badge bg="light" text="dark" pill>
                  {vacantRoomsCount}
                </Badge>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4}>
          <Link to="/VacantRooms">
            <Card className="mb-4 card-shadow bg-warning-gradient text-white hover-focus">
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title>
                    <FaSignOutAlt className="me-2" /> Tenants Moving Out
                  </Card.Title>
                  <Card.Text>Manage tenants moving out</Card.Text>
                </div>
                <Badge bg="light" text="dark" pill>
                  {tenantsMovingOutCount}
                </Badge>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6}>
          <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
            <Card.Body>
              <Card.Title>Total Received This Month</Card.Title>
              <Card.Text className="text-success">
                <h3>KSh {totalReceived.toLocaleString()}</h3>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
            <Card.Body>
              <Card.Title>Total Pending Amount</Card.Title>
              <Card.Text className="text-danger">
                <h3>KSh {totalPending.toLocaleString()}</h3>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card className="mb-4 card-shadow bg-light-gradient hover-focus">
            <Card.Body className="d-flex flex-column align-items-center">
              <Card.Title>Received to Total Ratio</Card.Title>
              <ReactSpeedometer
                value={percentage}
                minValue={0}
                maxValue={100}
                needleColor="red"
                startColor="green"
                segments={10}
                endColor="blue"
                textColor="black"
                height={200}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CaretakerDashboard;
